import api from '@/plugins/axios';
import { ApiKey, ApiKeyCall } from '@/types';

export const ListApiKeys = async (): Promise<ApiKey[]> => {
  const { data } = await api.get<ApiKey[]>('/api-keys')

  return data;
}

export const GetApiKey = async (apiKeyId: string) => {
  const { data } = await api.get<ApiKey>(`/api-keys/${apiKeyId}`);

  return data;
}

export const CreateApiKey = async (apiKey: Partial<ApiKey>) => {
  await api.post('/api-keys', apiKey);
}

export const UpdateApiKey = async (apiKey: Partial<ApiKey>) => {
  await api.patch(`/api-keys/${apiKey.id}`, apiKey);
}

export const ListApiKeyCalls = async (apiKeyId: string) => {
  const { data } = await api.get<ApiKeyCall[]>(`/api-keys/${apiKeyId}/calls`)

  return data;
}