
import { Component, Vue } from "vue-property-decorator";

import * as ApiServices from '@/services/ApiServices';
import { ROOT_ACTIONS } from "@/store/actions";

import { ApiKey, ApiKeyCall } from '@/types'
import { format } from "date-fns";


@Component({})
export default class Index extends Vue {
  calls = [] as ApiKeyCall[]
  apiKey = null as null | ApiKey;
  apiKeyCopy = {} as ApiKey;

  loading = {
    apiKey: true,
    calls: true,
    update: false,
  }

  mounted() {
    this.fetchApiKey();
    this.fetchCalls();
  }

  async fetchApiKey() {
    try {
      this.apiKey = await ApiServices.GetApiKey(this.$route.params.apiKeyId);
      Object.assign(this.apiKeyCopy, this.apiKey);
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.apiKey = false;
    }
  }

  async fetchCalls() {
    try {
      this.calls = await ApiServices.ListApiKeyCalls(this.$route.params.apiKeyId)
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.calls = false;
    }
  }

  async update() {
    try {
      this.loading.update = true;
      await ApiServices.UpdateApiKey({
        id: this.apiKey?.id,
        name: this.apiKeyCopy.name,
        limit: this.apiKeyCopy.limit,
        enabled: this.apiKeyCopy.enabled,
        allowFullText: this.apiKeyCopy.allowFullText,
      })
      this.fetchApiKey();
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Sleutel bewerkt'
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.update = false;
    }
  }

  formatDate(d: string | Date): string {
    return format(new Date(d), "dd-MM-yyyy hh:mm:ss");
  }
}
